.carousel {
    margin: 0 auto;
    overflow: hidden;
    display: flex;
}
.carousel > * {
    flex: 0 0 auto; /* Prevent shrinking */
    width: auto; /* Ensure children maintain natural size */
}
.group {
    display: flex;
    will-change: transform;
    animation: scrolling var(--animation-duration, 5s) linear infinite;
    /* Use a CSS variable for dynamic translateX */
    transform: translateX(var(--translate-x, 0));
    animation-delay: var(--animation-delay, 0s)
}

@keyframes scrolling {
    0% {
        transform: translateX(var(--translate-x-start, 0));
    }
    100% {
        transform: translateX(var(--translate-x-end, 0));
    }
}

.card {
    width: 100%;
    color: white;
    font-size: xx-large;
    justify-content: center;
    align-items: center;
    /*min-height: 200px; */
}

@media screen and (max-width: 768px) {
      .card {
        height: auto; /* Allow natural height adjustment */
        max-height: 70%; /* Reduce the current height to half */
        width: auto; /* Maintain aspect ratio */
        object-fit: contain; /* Prevent stretching or cropping */
        padding-top: 45px; /* Add space at the top to push the image down */
    }

    .carousel {
        height: calc(70%); /* Scale down the carousel container */
     
    }
}

