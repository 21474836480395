.scale-up {
    display: inline-block; /* Ensures the element can be transformed */
    animation: scaleUp 0.25s ease-in-out forwards; /* Animation properties */
    transition: transform 0.25s ease-in-out;
}

@keyframes scaleUp {
    0% {
        transform: scale(0.5); /* Start at half size */
        opacity: 0; /* Start invisible */
    }
    100% {
        transform: scale(1); /* End at full size */
        opacity: 1; /* End fully visible */
    }
}

.scale-up:hover {
    transform: scale(1.1); /* Scale up to 1.1 on hover */
}