.main {
    display:flex;
    --s: 65px;  /* size  */
    --m: 1.5px;    /* margin */
    --f: calc(1.732 * var(--s) + 4 * var(--m)  - 1px);
}

.container {
    font-size: 0; /*disable white space between inline block element */
}

.container div {
    width: var(--s);
    margin: var(--m);
    height: calc(var(--s)*1.1547);
    display: inline-block;
    font-size:initial;
    clip-path: polygon(0% 25%, 0% 75%, 50% 100%, 100% 75%, 100% 25%, 50% 0%);
    background: #222125;
    margin-bottom: calc(var(--m) - var(--s)*0.2885);
    transition: transform 0.3s ease; /* Smooth transition for scaling */
}

.container div:nth-child(odd) {
    background: #222125;
}

.container div:hover {
    transform: scale(1.25); /* Scale up on hover */
    /*box-shadow: 0 0 0 16px aqua;*/
}

.container::before {
    content: "";
    width: calc(var(--s)/2 + var(--m));
    float: left;
    height: 120%;
    shape-outside: repeating-linear-gradient(
            #0000 0 calc(var(--f) - 3px),
            #000  0 var(--f));
}

/* Mobile-Specific Adjustments */
@media screen and (max-width: 768px) {
    .container {
        display: none; /* Hide hexagonal grid on mobile */
    }

    .main {
        background: #333; /* Solid dark gray background */
    }
}
